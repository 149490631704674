<template>
    <div>
        <Breadcrumb titulo="Categorias de Produtos" :items="item" backgroundColor="#ff0b0b" />
        <div id="container_categorias" class="container_categorias">
            <div class="row">
                <div class="col-sm d-flex flex-row ml-0 mr-3">
                    <b-input-group class="tabela-mensagens-padrao-input-pesquisar mr-3">

                        <b-form-input class="input-pesquisa-input"
                                      type="search"
                                      placeholder="Pesquisar..."
                                      v-model="filterNome"
                                      tabindex="1"
                                      @keyup.enter="filtrar_categ">
                        </b-form-input>

                        <b-input-group-prepend is-text
                                               class="input-pesquisa-icone"
                                               tabindex="2"
                                               @keyup.enter="filtrar_categ"
                                               @click="filtrar_categ">
                            <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>

                    </b-input-group>
                </div>
                <div class="ml-3 mr-3">
                    <botao-padrao @click="criar_capanha" texto="Nova categoria" class="btn-ajustes btn_mod_categorias_prod" />
                </div>
            </div>
            <b-table :items="listaCategorias"
                     :fields="fields"
                     selected-variant=""
                     ref="selectableTable"
                     responsive="md"
                     striped
                     hover
                     class="tabela-padrao-btable app-scroll-custom">

                <template #cell(Disponivel)="row">
                    <span class="app-ck-html-container" v-html="row.item.Inclusao">
                    </span>
                </template>

                <template #cell(acoes)="row">
                    <botao-tab-edit @click="editar_categoria(row.item)" />
                    <botao-tab-remove @click="exclui_categoria(row.item)" />
                </template>



            </b-table>
            <Paginacao-Seta :selecionado="selecionado"
                            :offset="offset"
                            @eventoPaginacao="selecionarPaginacaoCategoriasProdutos" />
        </div>
        <ModalCategorias ref="modal_categorias"
                         :itensEdicao="itemCampanha"
                         :editarCategoria="editarCategoria"
                         @retornoCarregamento="carregaCategoriasRetorno($event)" />


    </div>
</template>
<script>

    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import ModalCategorias from '@/components/produtos/ModalCategoria.vue'

    import ProdutosServices from '@/assets/js/services/ProdutosServices'
    import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta'

    import InputPesquisa from '@/components/inputs/InputPesquisa.vue'
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'

    export default {
        name: 'CategoriasProdutosPage',
        components: {
            Breadcrumb,
            ModalCategorias,
            PaginacaoSeta,
            InputPesquisa,
            BotaoPadrao,
            BotaoTabEdit,
            BotaoTabRemove
        },

        data: function () {
            return {
                fields: [
                    { key: 'Codigo', label: 'Codigo', sortable: true, sortDirection: 'asc' },
                    { key: 'Nome', label: 'Nome', sortable: true, sortDirection: 'asc' },
                    { key: 'Disponivel', label: 'Data Inclusão', sortable: true, sortDirection: 'asc' },
                    { key: 'acoes', label: 'Ações', class: 'text-center' }
                ],
                item: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-cogs',
                        texto: 'Produtos',
                        link: '/produtos'
                    },
                    {
                        id: '3',
                        classe: 'fas fa-boxes',
                        texto: 'Categorias',
                        link: '/produtos-categorias'
                    }
                ],
                exibe: false,
                itensBreacrumb: [],
                listaCategorias: [],
                itemCampanha: null,
                itemDisparo: null,
                editarCategoria: false,

                filterNome: '',
                limit: 10,
                offset: 1,
                selecionado: 1,
                typePaginacao: 'paginandoCategoriasProdutos'
            }
        },
        created() {
            this.carregaCategorias();
        },

        methods: {
            carregaCategorias: async function () {
                var totalMaximo = await ProdutosServices.tamanhoMaximoCategoriasProdutos()
                $("#caixa_carregamento").show()
                this.listaCategorias = await ProdutosServices.carregaCategoriasProdutos();
                this.offset = totalMaximo % this.limit != 0 ?
                    Math.floor(totalMaximo / this.limit) + 1 :
                    Math.floor(totalMaximo / this.limit)
                $("#caixa_carregamento").hide()
            },
            carregaCategoriasRetorno: function (retornoCarregamento) {
                this.listaCategorias = retornoCarregamento;
            },
            abrir_disparo: function (item) {

                this.itemDisparo = item;
                this.$refs.modal_disparo.show()

            },
            criar_capanha: function () {
                this.editarCategoria = false;
                this.$refs.modal_categorias.show()
                this.itemCampanha = null;
            },
            editar_categoria: function (item) {
                this.itemCampanha = item;
                this.editarCategoria = true;
                this.$refs.modal_categorias.show()

            },
            exclui_categoria: async function (item) {
                $("#caixa_carregamento").show()
                this.listaCategorias = await ProdutosServices.deletaCategoria(item.Id);
                this.carregaCategorias();
                $("#caixa_carregamento").hide()

            },
            criar_lista_disparo: function () {
                this.$refs.modal_lista_disparo.show()
            },
            async filtrar_categ() {
                $("#caixa_carregamento").show()
                this.listaCategorias = await ProdutosServices.filtrarCategorias(this.filterNome)
                this.offset = Math.floor(this.listaCategorias.length / this.limit) + 1
                this.selecionado = 1
                $("#caixa_carregamento").hide()
            },
            computedClass(index) {

                if (index % 2 != 0) {
                    return "linha_par"
                }
            },
            async selecionarPaginacaoCategoriasProdutos(num) {
                this.selecionado = num

                $("#caixa_carregamento").show()
                this.listaCategorias = await ProdutosServices.paginandoCategoriasProdutos((num - 1), this.filterNome)
                $("#caixa_carregamento").hide()
            },
        },
    }
</script>

<style scoped>
    #container_categorias {
        padding: 10px;
        background-color: #f3f3f3;
        margin: 12px 8px;
    }

    .tabela-mensagens-padrao-input-pesquisar {
        width: 400px !important;
    }

    .btn-ajustes {
        width: 180px;
        background-color: var(--cinza-2);
    }

    .btn_mod_categorias_prod {
        background-color: var(--cor-primaria-cliente);
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        padding: 10px;
        float: right;
        text-align: center;
    }

        .btn_mod_categorias_prod:hover {
            background-color: #1b99a3;
        }

    .tabela-padrao-btable {
        background-color: #fff;
        height: calc(100vh - 250px);
        overflow-y: auto;
        margin-bottom: 0;
        font-size: 12px;
        padding: 0 7px 7px 7px;
        margin: 8px 0
    }

    .input-pesquisa-icone {
        height: 35px;
        cursor: pointer;
    }

        .input-pesquisa-icone:hover {
            opacity: 0.6;
            border: 1px var(--cor-primaria-cliente) solid;
        }

    .input-pesquisa-input {
        height: 35px;
        border-radius: inherit;
        border: none;
        box-shadow: none;
        font-size: inherit;
        font-weight: bold;
        color: var(--cinza-5);
    }
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        .input-pesquisa-input::placeholder {
            color: var(--cinza-5);
            opacity: 1; /* Firefox */
        }
        /* Internet Explorer 10-11 */
        .input-pesquisa-input:-ms-input-placeholder {
            color: var(--cinza-5);
        }
        /* Microsoft Edge */
        .input-pesquisa-input::-ms-input-placeholder {
            color: var(--cinza-5);
        }

        .input-pesquisa-input:hover {
            border: 1px var(--cor-primaria-cliente) solid;
        }

        .input-pesquisa-input:active {
            border: 1px var(--cor-primaria-cliente) solid;
            border-radius: inherit;
        }
</style>